.textfield {
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
  width: 100%;
  padding-right: 10px;
}

input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

input:focus {
  background-color: rgb(31, 34, 36);
}
