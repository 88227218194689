.menuBarContainer {
  display: flex;
  position: relative;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.menuBarButton {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  opacity: 0;
  background-color: #2c2c2c;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: 0.45s ease;
  box-sizing: border-box;
  z-index: -1;
}

.menuBarBtnImg {
  display: block;
  background-color: #000000;
  box-sizing: border-box;
  transform: scale(0.9);
}

.menuBarContainer:hover .menuBarButton {
  width: 18rem;
  opacity: 1;
  transition: 0.25s linear;
}

.menuBarContainer:hover .menuBarBtnImg {
  transition: all 0.2s ease-in-out;

  transform: scale(1);
  opacity: 1;
  filter: none;
}

.menuBarContainer:hover .menuBarBackBtn {
  color: #ffffff;
}

.menuBarBtnTxt {
  width: 10rem;
  justify-self: right;
}

.menuBarBtnTxt p {
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}

.menuBarBackBtn {
  color: #bababa;
  display: block;
  text-align: center;
  font-size: 6rem;
  font-weight: 600;
  line-height: 5rem;
  width: 6rem;
  height: 6rem;
  margin: 0;
  transition: 0.75s linear;
}

/* sidebarAnimationPs */
.sidebarAnimationPs-appear {
  opacity: 0;
}

.sidebarAnimationPs-appear.sidebarAnimationPs-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
