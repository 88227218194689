.financesContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
}
.exchangesContent {
  height: auto;
  color: #fff;
  z-index: 100;
  grid-row: 1;
  grid-column: 3/4;
}

.totalsVelocityContent {
  height: auto;
  grid-row: 1;
  grid-column: 4/5;
  color: #fff;
  z-index: 100;
}

.totalsInvoiceContent {
  height: auto;
  grid-row: 1;
  grid-column: 5/6;
  color: #fff;
  z-index: 100;
}

.financesContent {
  margin: 0 0 4rem;
  grid-row: 2;
  grid-column: 2/7;
  color: #fff;
}

.revenueChartContent {
  grid-row: 1/3;
  grid-column: 8/9;
  background: #282b30;
  color: #fff;
  z-index: 100;
  height: 27.8rem;
}

.revenueChartContent canvas.chartjs-render-monitor {
}

.tableHeader {
  background-color: #24272b;
  text-align: center;
  border: 2px solid #2f3238;
  font-size: 1.7rem;
  line-height: 4rem;
  color: #fff;
}

.tableContent {
  position: relative;
  background-color: #282b30;
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  line-height: 4rem;
  table-layout: fixed;
}

.tableContentSticky {
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  table-layout: fixed;
  line-height: 4rem;
  position: sticky;
  top: 21.5rem;
  z-index: 100;
}

.scrollableTbody {
  height: 100% !important;
}

.exchangeTableBody {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.financesContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.exchangesContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.totalsVelocityContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.totalsInvoiceContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.financesContent table,
.financesContent td,
.totalsInvoiceContent table,
.totalsInvoiceContent td,
.totalsVelocityContent td,
.totalsVelocityContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
  table-layout: fixed !important;
}

.financesContent td,
.totalsInvoiceContent td,
.totalsVelocityContent td {
  height: 4rem;
}

.financesContent td:first-child {
  width: 4rem !important;
}

.exchangesContent td,
.exchangesContent td {
  height: calc(4.1rem);
  width: 10rem;
}

.financesContent .tableMarkedRow {
  background-color: #15826e;
  color: white;
}

.financesInvCells {
  text-align: center;
  border: none;
  background-color: #2f3238;
  color: #afafaf;
  width: 6rem !important;
  cursor: pointer;
  font-size: 1.2rem;
  max-width: 3rem;
}

.financesInvCells td {
  height: 4rem;
  width: 6rem !important;
}

.financesIcon {
  height: 3.3rem;
  width: 3.3rem;
  margin: auto;
}

img.financesIcon {
  border-radius: 5px;
}

.financesIconBtnCont {
  display: flex;
  background-color: #2f3238;
  height: 4rem;
  width: 4rem;
  margin: auto;
}

.financesGameBtnCont {
  display: flex;
  background-color: #2f3238;
  height: 4rem;
  width: 4rem;
}

.financesGameButton {
  display: flex;
  height: 4rem;
  width: 4rem;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
}

.lastUpdateInfo {
  display: inline-block;
  color: #00d5a9;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3rem;
  cursor: pointer;
  float: right;
  padding-right: 0.3rem;
  padding-top: 2.3rem;
  vertical-align: bottom;
  z-index: 11;
}

.lastUpdateTooltip {
  background-color: #404040;
  position: absolute;
  width: 23rem;
  height: 5rem;
  top: 20rem;
  left: 30rem;
  z-index: 101;
  font-size: 1.2rem;
  border: 2px solid #353535;
}

.lastUpdateTooltipText {
  padding: 0.5rem;
}

.lastUpdateTooltipDate {
  margin-left: 1rem;
  color: #00d5a9;
  text-align: center;
}

.sticky {
  position: sticky;
  top: 14rem;
}

@media only screen and (max-width: 1px) {
  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.4rem;
    color: #fff;
  }
}

@media only screen and (max-width: 1px) {
  .exchangesContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 8rem;
    left: 14rem;
    color: #fff;
  }

  .totalsVelocityContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 15rem;
    left: calc(2rem + 22vw);
    color: #fff;
  }

  .totalsInvoiceContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 22rem;
    left: 14rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;
    width: calc(85% - 40.2rem);
    height: 21rem;
    top: 8.2rem;
    left: calc(15% + 30rem);
    color: #fff;
  }

  .financesContent {
    position: absolute;
    height: auto;
    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 23rem;
    left: 4rem;
    color: #fff;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;

    table-layout: fixed;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 8rem;
    width: 100vw;
    height: 31.5rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: sticky;
    top: 29.5rem;
    z-index: 100;
  }

  .totalsInvoiceContent td,
  .totalsVelocityContent td {
    height: 2rem;
  }

  .exchangesContent td {
    height: 2.4rem;
  }
}

@media only screen and (max-width: 1px) {
  .exchangesContent {
    position: fixed;
    height: auto;

    width: calc(30% - 7rem);
    top: 8rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .totalsVelocityContent {
    position: fixed;
    height: auto;

    width: calc(35% - 8rem);
    top: 8rem;
    left: calc(30% + 7rem);
    color: #fff;
    z-index: 100;
  }

  .totalsInvoiceContent {
    position: fixed;
    height: auto;
    width: calc(35% - 8rem);
    top: 8rem;
    left: calc(65% - 2rem);
    color: #fff;
    z-index: 100;
  }

  .financesContent {
    position: absolute;

    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 31.5rem;
    left: 4rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;

    width: calc(100% - 24.4rem);
    top: 16rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.2rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 0rem;
    width: 100%;
    height: 39.5rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;

    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: sticky;
    top: 37.5rem;
    z-index: 100;
  }
}

@media only screen and (max-width: 1px) {
  .chartTitle {
    color: #fff;
    padding: 0.1rem;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;
    margin: 0.5rem;
    border-bottom: 1px solid #606064;
    margin-top: 0;
  }

  .totalsInvoiceContent td,
  .totalsVelocityContent td {
    height: 1rem;
  }

  .exchangesContent td {
    height: 1.4rem;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    line-height: 1rem;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;
    height: 17rem;
    width: calc(100% - 24.4rem);
    top: 13rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .financesContent {
    position: absolute;
    /: auto;
    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 24rem;
    left: 4rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 0rem;
    width: 100%;
    height: 32.8rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;
    /: 100 %;
    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: sticky;
    top: 30.5rem;
    z-index: 100;
  }
}
