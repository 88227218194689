.dropdownBox {
  top: 0.5rem;
  right: 0.1rem;
  z-index: 2;
  width: 10rem;
  display: inline-block;
  float: right;
  margin-bottom: 20px;
}

.chartTitle {
  color: #fff;
  padding-bottom: 0.6rem;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 600;
  margin: 1rem;
  border-bottom: 1px solid #606064;
  margin-top: 0;
}

.baseChart {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 6rem;
  background: #282b30;

  height: 100%;
  width: 100%;
}

.baseChart ::selection {
  background: transparent;
}

.baseChart ::-moz-selection {
  background: transparent;
}

.dropdownBox select {
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  display: inline-block;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3a3a3a;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.dropdownBox select option {
}

.dropdown-toggle {
  background-color: transparent !important;
}

/*
.dropdown {
  margin-right: 1rem;
  color: #fff;
  font-weight: normal; }

.field {
  padding: 0;
  color: #fff; }

.values {
  padding-left: 0;
  color: #ffffff;
  background-color: #2f3238 !important; }

.templateValue {
  min-height: initial;
  padding: 0.8rem;
  border-radius: 0.2rem;
  font-weight: normal;
  background: none;
  color: #fff; }
.templateValue::after {
  border-top-color: #fff; }

.inputLabel {
  color: #fff; }

.inputValue {
  color: #fff; }

.inputTemplateValue {
  color: #fff; }

.inputElement {
  border-bottom-color: #ffffff;
  margin: 0 .5rem;
  width: calc(100% - 1rem);
  text-align: center;
  color: #ffffff; }
.inputElement::placeholder {
  color: #ffffff; }
*/
