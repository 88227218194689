.loginForm {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  max-width: 90%;
}

.loginForm button {
  width: 260px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: medium;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;

  background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.loginForm button:hover {
  background-image: -moz-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
}

.loginForm button:focus {
  outline: none;
}

.loginForms button {
  font-size: 1.3rem;
  width: 100%;
  font-weight: 600;
  line-height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 1px 2px #000000;
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 3px;
  transition: all 0.01s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.loginForms button:hover {
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 3px;
  box-shadow: 0 0 0px #000000;
  transition: all 0.01s linear;
}

.loginForm input[type='email'] {
  width: 100%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  line-height: 3rem;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.loginForm input[type='password'] {
  width: 100%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  line-height: 3rem;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.loginForm input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.loginForm input:focus {
  background-color: rgb(31, 34, 36);
}

.loginForm button:focus {
  outline: none;
}

/* SWITCH */
.toogleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.toogleLabel {
  color: #5b616e;
  font-size: 1.3rem;
  margin-left: 0.4rem;
}

.toogleContainer input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
}

.toogleContainer label {
  margin: 0;
  cursor: pointer;
  text-indent: -9999px;
  width: 3.5rem;
  height: 1.3rem;
  background: #1e2022;
  display: block;
  border-radius: 1.8rem;
  position: relative;
}

.toogleContainer label:after {
  content: '';
  position: absolute;
  top: -0.25rem;
  left: 0rem;
  width: 1.8rem;
  height: 1.8rem;
  background: #40444c;
  border-radius: 1.8rem;
  transition: 0.1s;
}

.toogleContainer input:checked + label {
  background: #009476;
  transition: all 0.55s linear;
}

.toogleContainer input:checked + label:after {
  left: 100%;
  background: #0e6355;
  transform: translateX(-100%);
}

.toogleContainer label:active:after {
  width: 3.5rem;
}

.toogleLabel {
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: #5b616e;
}
