.TitleBar {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44);
  line-height: 6rem;
  font-size: 2.2rem;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 3rem;
  color: #ffffff;
  font-weight: 600;
  z-index: 10001;
  font-family: 'Titillium Web', sans-serif;
}

.resetPass {
  border-bottom: 1px solid #3a3a3a;
  padding-left: 37px;
  padding-bottom: 13px;
  padding-top: 3px;
  cursor: pointer;
  display: block;
}
