.popup {
  position: fixed;
  width: 50rem;
  height: auto;
  top: 50%;
  left: 50%;

  border-radius: 5px;
  padding-top: 2rem;
  padding-right: 6rem;
  padding-left: 6rem;
  padding-bottom: 2rem;
  background-color: #24272b;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.popupForm {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #818184;
}

.popup .textfield.formText {
  width: 65%;
}

label.labelForm {
  display: inline-block;
  padding-right: 10px;
  width: 100px;
  text-align: right;
}

label.labelGroups {
  display: inline-block;
  clear: both;
  padding-right: 10px;
  width: 100px;
  text-align: right;
  float: left;
}

input {
  display: inline-block;
}

.submitBtn {
  width: 10rem;
  margin-right: 5rem;
}

.submitMoreBtn {
  width: 20rem;
}

.submitEdit {
  width: 10rem;
}

.deleteBtn,
.cancelBtn {
  width: 10rem;
}
.deleteBtn {
  margin-right: 5rem;
}

.checkboxItem.popupCheckbox {
  font-size: 12px;
  letter-spacing: 0.075rem;
}

.popupUser .textfield {
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.pupupUser input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.popupUser input:focus {
  background-color: rgb(31, 34, 36);
}

.labelContainer {
  margin-bottom: 20px;
}

.popup button {
  position: relative;
  width: 16rem;
  bottom: 0rem;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 2px 3px #000000;
  margin: 2px 1rem 3px;
  transition: all 0.1s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.popup button:hover {
  margin: 3px 1rem 2px;
  box-shadow: 0 0 1px #606064;
  transition: all 0.1s linear;
}

.confrmationContainer {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 520px;
}

.confrmationContainer button {
  all: unset;
  flex-grow: 1;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 2px 3px #000000;
  margin: 2px 1rem 3px;
  transition: all 0.1s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.confrmationContainer button:hover {
  margin: 3px 1rem 2px;
  box-shadow: 0 0 1px #606064;
  transition: all 0.1s linear;
}
