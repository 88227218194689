.pjLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.pjLoading {
  position: absolute;
  top: calc(6rem + 50%);
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f37664;
  font-size: 2rem;
}

.pjSlice {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.5rem;
  border: 2.5rem solid transparent;
  border-left-width: 1.45rem;
  border-right-width: 1.45rem;
  border-top-color: #ef4972;
  transform: translate(-50%, -50%);
}

.pjSlice:nth-child(1) {
  border-top-color: #ef4972;
  transform: translate(-50%, -50%) rotate(0deg) scale(0);
  animation: sparkleLoad-1 1s 0.17s infinite;
}

.pjSlice:nth-child(2) {
  border-top-color: #ef495f;
  transform: translate(-50%, -50%) rotate(60deg) scale(0);
  animation: sparkleLoad-2 1s 0.34s infinite;
}

.pjSlice:nth-child(3) {
  border-top-color: #ef494b;
  transform: translate(-50%, -50%) rotate(120deg) scale(0);
  animation: sparkleLoad-3 1s 0.51s infinite;
}

.pjSlice:nth-child(4) {
  border-top-color: #ef5a49;
  transform: translate(-50%, -50%) rotate(180deg) scale(0);
  animation: sparkleLoad-4 1s 0.68s infinite;
}

.pjSlice:nth-child(5) {
  border-top-color: #ef6d49;
  transform: translate(-50%, -50%) rotate(240deg) scale(0);
  animation: sparkleLoad-5 1s 0.85s infinite;
}

.pjSlice:nth-child(6) {
  border-top-color: #ef8149;
  transform: translate(-50%, -50%) rotate(300deg) scale(0);
  animation: sparkleLoad-6 1s 1.02s infinite;
}

@keyframes sparkleLoad-1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
}

@keyframes sparkleLoad-2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(60deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
}

@keyframes sparkleLoad-3 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(120deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
}

@keyframes sparkleLoad-4 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(180deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
}

@keyframes sparkleLoad-5 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(240deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
}

@keyframes sparkleLoad-6 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    transform: translate(-50%, -50%) rotate(300deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
}

.loaderContainer {
  width: 14rem;
  height: 14rem;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: 201;
  box-sizing: border-box;
  background-color: #282b30;
  transform: translate(-50%, -50%);
}

.loader {
  color: #bdff42;
  font-size: 16px;
  margin-top: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  animation: myAnimation2 1.7s infinite linear;
  transform: translateZ(0);
}

@keyframes myAnimation {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    color: #56aaed;
  }
  25% {
    color: #92268f;
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    color: #db46b8;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    color: #00d5a9;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    color: #8492b7;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    color: #b7b562;
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    color: #f37926;
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes myAnimation2 {
  0% {
    box-shadow: 0 -3em 0 0.2em #decb05, 2em -2em 0 0em #decb05, 3em 0 0 -1em #decb05,
      2em 2em 0 -1em #decb05, 0 3em 0 -1em, -2em 2em 0 -1em #decb05, -3em 0 0 -1em #decb05,
      -2em -2em 0 0 #decb05;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #de24a2, 2em -2em 0 0.2em #de24a2, 3em 0 0 0 #de24a2,
      2em 2em 0 -1em #de24a2, 0 3em 0 -1em #de24a2, -2em 2em 0 -1em #de24a2, -3em 0 0 -1em #de24a2,
      -2em -2em 0 -1em #de24a2;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #00d5a9, 2em -2em 0 0 #00d5a9, 3em 0 0 0.2em #00d5a9,
      2em 2em 0 0 #00d5a9, 0 3em 0 -1em #de3240, -2em 2em 0 -1em #de3240, -3em 0 0 -1em #de3240,
      -2em -2em 0 -1em #de3240;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em #006ede, 2em -2em 0 -1em #006ede, 3em 0em 0 0 #006ede,
      2em 2em 0 0.2em #006ede, 0 3em 0 0em #006ede, -2em 2em 0 -1em #006ede, -3em 0em 0 -1em #006ede,
      -2em -2em 0 -1em #006ede;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0 #f37926, 2em -2em 0 -1em #f37926, 3em 0 0 -1em #f37926,
      2em 2em 0 -1em #f37926, 0 3em 0 -1em #decb05, -2em 2em 0 0 #decb05, -3em 0em 0 0 #decb05,
      -2em -2em 0 0.2em #decb05;
  }
  100% {
    opacity: 1;
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
}
