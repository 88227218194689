html,
body {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}

#root {
  min-height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: 'Roboto', sans-serif;
  /*font-family: 'Titillium Web', sans-serif;*/
}

body {
  position: relative;
  font-size: 1.6rem;
  /*overflow-x: hidden;*/
  color: #fafafa;
  background-color: #2f3238;
  -webkit-overflow-scrolling: touch;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
