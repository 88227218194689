.MenuBar {
  flex: 0 0 6rem;
  flex-direction: column;
  width: 6rem;
  display: flex;
  color: #ffffff;
  background-color: #282b30;
  align-items: center;
  min-height: 100%;
  padding: 0rem 0rem 0rem;
  box-sizing: border-box;
  font-weight: 600;
  z-index: 299;
}
