.trendsContent {
  grid-column: 2/4;
  grid-row: 2;
  top: 6rem;
  color: #fff;
}

.trendsContent table {
  border: 0px solid #2f3238;
  border-collapse: collapse;
  table-layout: auto !important;
  height: 4rem;
}

.trendsContent td {
  border: 2px solid #36393f;
  border-collapse: collapse;
  table-layout: auto !important;
  height: 86px;
  padding: 1rem;
  cursor: pointer;
}

.trendsContent td:first-child {
  width: 3rem !important;
}

.trendsTableContent {
  background-color: #282b30;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  vertical-align: middle;
  color: #99999c;
  table-layout: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.trendsTableHeader {
  background-color: #24272b;
  text-align: center;
  border: 0px solid #2f3238;
  font-size: 12px;

  color: #e1e1e5;
  font-weight: bolder;
  letter-spacing: 0.05rem;
}

.trendsTableHeader td {
  cursor: default !important;
  height: 91px;
}

.trendsTableHeader p {
  font-size: 10px;
  padding-top: 0px;
  color: #99999c;
  font-weight: 400;
}

.trendsTableHeader tr td {
  padding-top: 18px;
  min-width: 102px;
}
