.kpiGameDetailsContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  box-sizing: border-box;
  overflow: auto;
}

.kpiViewTitle {
  width: 80%;
  color: #fff;
  padding: 0rem 0rem 1.2rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  z-index: 101;
}

.kpiGameDetailsGridContainer {
  display: grid;
  grid-auto-rows: 9rem;
  grid-template-columns: 12rem 14rem 14rem 14rem 14rem 14rem 14rem 15rem;
  grid-template-rows: 11rem 5rem 7rem;
  grid-gap: 0.2rem;
  grid-row-gap: 2.5rem;
}

.kpiDtInfo {
  grid-column: 1/2;
  grid-row: 1;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05rem;
  margin-top: 4rem;
  color: #99999c;
}

.kpiDtInfo span {
  display: block;
  height: 3rem;
  text-align: right;
  padding-right: 15px;
}

.totalContainer {
  display: grid;
  grid-template-column: 1rem 14rem 14rem 1rem;
}

.kpiDataRange {
}

.tldt1 {
  grid-column: 2/3;
  grid-row: 1;
}

.tldt2 {
  grid-column: 3/4;
  grid-row: 1;
}

.kpiGameDetailsGridContainer .dt1 {
  grid-column: 2 / 3;
  grid-row: 1;
}

.kpiGameDetailsGridContainer .dt2 {
  grid-column: 3;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt3 {
  grid-column: 4 / 5;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt4 {
  grid-column: 5 / 6;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt5 {
  grid-column: 6 /7;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt6 {
  grid-column: 7 / 8;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt7 {
  grid-column: 2/3;
  grid-row: 2;
}

.kpiGameDetailsGridContainer .dt8 {
  grid-column: 3 / 4;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt9 {
  grid-column: 4 / 5;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt10 {
  grid-column: 5 / 6;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt11 {
  grid-column: 6 / 7;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt12 {
  grid-column: 8 / 9;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt13 {
  grid-column: 2/3;
  grid-row: 3;
}

.kpiGameDetailsGridContainer .dt14 {
  grid-row: 3;
  grid-column: 3/4;
}
.kpiGameDetailsGridContainer .dt15 {
  grid-row: 3;
  grid-column: 4/5;
}
.kpiGameDetailsGridContainer .dt16 {
  grid-row: 3;
  grid-column: 5/6;
}
.kpiGameDetailsGridContainer .dt17 {
  grid-row: 3;
  grid-column: 6 /7;
}
.kpiGameDetailsGridContainer .dt18 {
  grid-row: 3;
  grid-column: 7/8;
}
.kpiGameDetailsGridContainer .dt19 {
  grid-row: 2;
  grid-column: 7/8;
}
.DateRangeIndicator {
  height: 4rem;
  color: #c7c7c7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
.DateRangeIndicatorClicker {
  padding: 0.5rem;
  background: rgba(31, 31, 31, 0.95);
  border: 2px solid #606064;
}

.kpiPlatformPicker {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: left;
  padding: 0.2rem;
}

.kpiPlatformPickerButton {
  cursor: pointer;
  width: 140px;
  height: 34px;
  border-color: rgb(28, 28, 28);
  border-radius: 3px;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(36, 39, 43) 0%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(36, 39, 43) 0%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(36, 39, 43) 0%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%
  );
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.25);
  /*box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);*/
  font-style: normal;
  padding-top: 1px;
  margin-right: 3rem;
  border-style: solid;
  border-width: 1px;
  font-size: 1.3rem;
  line-height: 3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

.kpiPlatformPickerButtonSelected {
  background-image: -moz-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;
}

.kpiPlatformPicker img {
  z-index: 100;
  position: relative;
  top: 4px;
  left: 3px;
  height: 2rem;
  padding-right: 30px;
  padding-left: 15px;
  text-align: left;
}

.kpiGameDetailsTotalLoan {
  width: 12rem;
  height: 2rem;
}

.kpiGameDetailsPeriodPicker {
  margin-left: 1rem;
}

.kpiGameDetailsRagStatus {
  width: 40rem;
}

.kpiGameDetailsDataTile {
  height: auto;
  width: 13rem;
  border: 1px solid #36393f;
  margin-right: 0rem;
  margin-bottom: 0rem;
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: bolder;
  display: inline-grid;
}

.kpiGameDetailsLabel {
  height: 3rem;
  text-align: center;
  display: block;
  color: #ffffff;
  background: #24272b;
  letter-spacing: 0.05rem;
  line-height: 3rem;
  font-weight: 500;
}

.kpiGameDetailsValues {
  height: 3rem;
  text-align: center;
  display: block;
  font-weight: normal;
  background-color: #282b30;
  color: #99999c;
  border-top: 1px solid #36393f;
  line-height: 3rem;
  box-sizing: border-box;
}

.kpiGameDetailsIgnitionStatusContainer {
  position: absolute;
  width: 40rem;
  top: 22rem;
  left: 6rem;
}

.componentP {
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 2rem;
  height: 22rem;
  width: 40rem;
}

.box {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0.5rem;
  background-color: #282b30;
  border: 1px solid #ffa119;
  color: #ffffff;
  text-align: center;
}
.box p {
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.box span {
  width: 10rem;
  float: right;
  line-height: 3rem;
}

.box span:first-child {
  float: left;
  text-align: left;
  padding-left: 1rem;
}

.box p:nth-child(even) {
  background-color: #24272b;
}

.boxRed {
  border-color: #f95846;
}

.boxGreen {
  border-color: #5bb960;
}

.header {
  padding: 0.3rem 1rem;
  margin: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: linear-gradient(to right, #ffa119 0%, #7e4316 100%);
  font-size: 1.8rem;
  line-height: 4rem;
  font-weight: bold;
}

.headerRed {
  background: linear-gradient(to right, #f95846 0%, #7f3f2f 100%);
}

.headerGreen {
  background: linear-gradient(to right, #5bb960 0%, #214321 100%);
}

.daysForRagStatus {
  margin-top: 2rem;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 4rem;
}

.kpiDataRange .RangePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 600px;
  top: 955px;
  left: 60px;
  z-index: 1009;
}

.kpiDataRange .RangePickerInfo {
  width: 30%;
  margin-top: 10px;
  background-color: #2f3238;
  z-index: 1080;
  text-align: left;
}

.cpiTile {
  grid-column: 8/9;
  grid-row: 3;
}
