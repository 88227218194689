.TitleBar {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44);
  line-height: 6rem;
  font-size: 2.2rem;
  width: 100%;
  top: 0;
  left: 0;
  padding-left: 3rem;
  color: #ffffff;
  font-weight: 600;
  z-index: 10001;
  font-family: 'Titillium Web', sans-serif;
}

.resetPass {
  border-bottom: 1px solid #3a3a3a;
  padding-left: 37px;
  padding-bottom: 13px;
  padding-top: 3px;
  cursor: pointer;
  display: block;
}

.pjLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.pjLoading {
  position: absolute;
  top: calc(6rem + 50%);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #f37664;
  font-size: 2rem;
}

.pjSlice {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.5rem;
  border: 2.5rem solid transparent;
  border-left-width: 1.45rem;
  border-right-width: 1.45rem;
  border-top-color: #ef4972;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.pjSlice:nth-child(1) {
  border-top-color: #ef4972;
  -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
          transform: translate(-50%, -50%) rotate(0deg) scale(0);
  -webkit-animation: sparkleLoad-1 1s 0.17s infinite;
          animation: sparkleLoad-1 1s 0.17s infinite;
}

.pjSlice:nth-child(2) {
  border-top-color: #ef495f;
  -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(0);
          transform: translate(-50%, -50%) rotate(60deg) scale(0);
  -webkit-animation: sparkleLoad-2 1s 0.34s infinite;
          animation: sparkleLoad-2 1s 0.34s infinite;
}

.pjSlice:nth-child(3) {
  border-top-color: #ef494b;
  -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(0);
          transform: translate(-50%, -50%) rotate(120deg) scale(0);
  -webkit-animation: sparkleLoad-3 1s 0.51s infinite;
          animation: sparkleLoad-3 1s 0.51s infinite;
}

.pjSlice:nth-child(4) {
  border-top-color: #ef5a49;
  -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(0);
          transform: translate(-50%, -50%) rotate(180deg) scale(0);
  -webkit-animation: sparkleLoad-4 1s 0.68s infinite;
          animation: sparkleLoad-4 1s 0.68s infinite;
}

.pjSlice:nth-child(5) {
  border-top-color: #ef6d49;
  -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(0);
          transform: translate(-50%, -50%) rotate(240deg) scale(0);
  -webkit-animation: sparkleLoad-5 1s 0.85s infinite;
          animation: sparkleLoad-5 1s 0.85s infinite;
}

.pjSlice:nth-child(6) {
  border-top-color: #ef8149;
  -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(0);
          transform: translate(-50%, -50%) rotate(300deg) scale(0);
  -webkit-animation: sparkleLoad-6 1s 1.02s infinite;
          animation: sparkleLoad-6 1s 1.02s infinite;
}

@-webkit-keyframes sparkleLoad-1 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
            transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
}

@keyframes sparkleLoad-1 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
            transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0);
            transform: translate(-50%, -50%) rotate(0deg) scale(0);
  }
}

@-webkit-keyframes sparkleLoad-2 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(0);
            transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(1);
            transform: translate(-50%, -50%) rotate(60deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(0);
            transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
}

@keyframes sparkleLoad-2 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(0);
            transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(1);
            transform: translate(-50%, -50%) rotate(60deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(60deg) scale(0);
            transform: translate(-50%, -50%) rotate(60deg) scale(0);
  }
}

@-webkit-keyframes sparkleLoad-3 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(0);
            transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(1);
            transform: translate(-50%, -50%) rotate(120deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(0);
            transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
}

@keyframes sparkleLoad-3 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(0);
            transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(1);
            transform: translate(-50%, -50%) rotate(120deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(120deg) scale(0);
            transform: translate(-50%, -50%) rotate(120deg) scale(0);
  }
}

@-webkit-keyframes sparkleLoad-4 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(0);
            transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(1);
            transform: translate(-50%, -50%) rotate(180deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(0);
            transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
}

@keyframes sparkleLoad-4 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(0);
            transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(1);
            transform: translate(-50%, -50%) rotate(180deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(180deg) scale(0);
            transform: translate(-50%, -50%) rotate(180deg) scale(0);
  }
}

@-webkit-keyframes sparkleLoad-5 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(0);
            transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(1);
            transform: translate(-50%, -50%) rotate(240deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(0);
            transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
}

@keyframes sparkleLoad-5 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(0);
            transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(1);
            transform: translate(-50%, -50%) rotate(240deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(240deg) scale(0);
            transform: translate(-50%, -50%) rotate(240deg) scale(0);
  }
}

@-webkit-keyframes sparkleLoad-6 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(0);
            transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(1);
            transform: translate(-50%, -50%) rotate(300deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(0);
            transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
}

@keyframes sparkleLoad-6 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(0);
            transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
  25%,
  75% {
    opacity: 0.8;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(1);
            transform: translate(-50%, -50%) rotate(300deg) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) rotate(300deg) scale(0);
            transform: translate(-50%, -50%) rotate(300deg) scale(0);
  }
}

.loaderContainer {
  width: 14rem;
  height: 14rem;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: 201;
  box-sizing: border-box;
  background-color: #282b30;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loader {
  color: #bdff42;
  font-size: 16px;
  margin-top: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-animation: myAnimation2 1.7s infinite linear;
          animation: myAnimation2 1.7s infinite linear;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

@-webkit-keyframes myAnimation {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    color: #56aaed;
  }
  25% {
    color: #92268f;
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    color: #db46b8;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    color: #00d5a9;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    color: #8492b7;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    color: #b7b562;
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    color: #f37926;
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes myAnimation {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    color: #56aaed;
  }
  25% {
    color: #92268f;
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    color: #db46b8;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    color: #00d5a9;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    color: #8492b7;
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    color: #b7b562;
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    color: #f37926;
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@-webkit-keyframes myAnimation2 {
  0% {
    box-shadow: 0 -3em 0 0.2em #decb05, 2em -2em 0 0em #decb05, 3em 0 0 -1em #decb05,
      2em 2em 0 -1em #decb05, 0 3em 0 -1em, -2em 2em 0 -1em #decb05, -3em 0 0 -1em #decb05,
      -2em -2em 0 0 #decb05;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #de24a2, 2em -2em 0 0.2em #de24a2, 3em 0 0 0 #de24a2,
      2em 2em 0 -1em #de24a2, 0 3em 0 -1em #de24a2, -2em 2em 0 -1em #de24a2, -3em 0 0 -1em #de24a2,
      -2em -2em 0 -1em #de24a2;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #00d5a9, 2em -2em 0 0 #00d5a9, 3em 0 0 0.2em #00d5a9,
      2em 2em 0 0 #00d5a9, 0 3em 0 -1em #de3240, -2em 2em 0 -1em #de3240, -3em 0 0 -1em #de3240,
      -2em -2em 0 -1em #de3240;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em #006ede, 2em -2em 0 -1em #006ede, 3em 0em 0 0 #006ede,
      2em 2em 0 0.2em #006ede, 0 3em 0 0em #006ede, -2em 2em 0 -1em #006ede, -3em 0em 0 -1em #006ede,
      -2em -2em 0 -1em #006ede;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0 #f37926, 2em -2em 0 -1em #f37926, 3em 0 0 -1em #f37926,
      2em 2em 0 -1em #f37926, 0 3em 0 -1em #decb05, -2em 2em 0 0 #decb05, -3em 0em 0 0 #decb05,
      -2em -2em 0 0.2em #decb05;
  }
  100% {
    opacity: 1;
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
}

@keyframes myAnimation2 {
  0% {
    box-shadow: 0 -3em 0 0.2em #decb05, 2em -2em 0 0em #decb05, 3em 0 0 -1em #decb05,
      2em 2em 0 -1em #decb05, 0 3em 0 -1em, -2em 2em 0 -1em #decb05, -3em 0 0 -1em #decb05,
      -2em -2em 0 0 #decb05;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #de24a2, 2em -2em 0 0.2em #de24a2, 3em 0 0 0 #de24a2,
      2em 2em 0 -1em #de24a2, 0 3em 0 -1em #de24a2, -2em 2em 0 -1em #de24a2, -3em 0 0 -1em #de24a2,
      -2em -2em 0 -1em #de24a2;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #00d5a9, 2em -2em 0 0 #00d5a9, 3em 0 0 0.2em #00d5a9,
      2em 2em 0 0 #00d5a9, 0 3em 0 -1em #de3240, -2em 2em 0 -1em #de3240, -3em 0 0 -1em #de3240,
      -2em -2em 0 -1em #de3240;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em #006ede, 2em -2em 0 -1em #006ede, 3em 0em 0 0 #006ede,
      2em 2em 0 0.2em #006ede, 0 3em 0 0em #006ede, -2em 2em 0 -1em #006ede, -3em 0em 0 -1em #006ede,
      -2em -2em 0 -1em #006ede;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0 #f37926, 2em -2em 0 -1em #f37926, 3em 0 0 -1em #f37926,
      2em 2em 0 -1em #f37926, 0 3em 0 -1em #decb05, -2em 2em 0 0 #decb05, -3em 0em 0 0 #decb05,
      -2em -2em 0 0.2em #decb05;
  }
  100% {
    opacity: 1;
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 1300ms ease-in;
  transition: opacity 1300ms ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  -webkit-transition: opacity 1.5s ease-in;
  transition: opacity 1.5s ease-in;
}

.MenuBar {
  flex: 0 0 6rem;
  flex-direction: column;
  width: 6rem;
  display: flex;
  color: #ffffff;
  background-color: #282b30;
  align-items: center;
  min-height: 100%;
  padding: 0rem 0rem 0rem;
  box-sizing: border-box;
  font-weight: 600;
  z-index: 299;
}

.menuBarContainer {
  display: flex;
  position: relative;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.menuBarButton {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  opacity: 0;
  background-color: #2c2c2c;
  text-align: center;
  justify-content: center;
  align-items: center;
  -webkit-transition: 0.45s ease;
  transition: 0.45s ease;
  box-sizing: border-box;
  z-index: -1;
}

.menuBarBtnImg {
  display: block;
  background-color: #000000;
  box-sizing: border-box;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.menuBarContainer:hover .menuBarButton {
  width: 18rem;
  opacity: 1;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
}

.menuBarContainer:hover .menuBarBtnImg {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(1);

          transform: scale(1);
  opacity: 1;
  -webkit-filter: none;
          filter: none;
}

.menuBarContainer:hover .menuBarBackBtn {
  color: #ffffff;
}

.menuBarBtnTxt {
  width: 10rem;
  justify-self: right;
}

.menuBarBtnTxt p {
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}

.menuBarBackBtn {
  color: #bababa;
  display: block;
  text-align: center;
  font-size: 6rem;
  font-weight: 600;
  line-height: 5rem;
  width: 6rem;
  height: 6rem;
  margin: 0;
  -webkit-transition: 0.75s linear;
  transition: 0.75s linear;
}

/* sidebarAnimationPs */
.sidebarAnimationPs-appear {
  opacity: 0;
}

.sidebarAnimationPs-appear.sidebarAnimationPs-appear-active {
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}

.financesContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
}
.exchangesContent {
  height: auto;
  color: #fff;
  z-index: 100;
  grid-row: 1;
  grid-column: 3/4;
}

.totalsVelocityContent {
  height: auto;
  grid-row: 1;
  grid-column: 4/5;
  color: #fff;
  z-index: 100;
}

.totalsInvoiceContent {
  height: auto;
  grid-row: 1;
  grid-column: 5/6;
  color: #fff;
  z-index: 100;
}

.financesContent {
  margin: 0 0 4rem;
  grid-row: 2;
  grid-column: 2/7;
  color: #fff;
}

.revenueChartContent {
  grid-row: 1/3;
  grid-column: 8/9;
  background: #282b30;
  color: #fff;
  z-index: 100;
  height: 27.8rem;
}

.revenueChartContent canvas.chartjs-render-monitor {
}

.tableHeader {
  background-color: #24272b;
  text-align: center;
  border: 2px solid #2f3238;
  font-size: 1.7rem;
  line-height: 4rem;
  color: #fff;
}

.tableContent {
  position: relative;
  background-color: #282b30;
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  line-height: 4rem;
  table-layout: fixed;
}

.tableContentSticky {
  width: 100%;
  font-size: 1.7rem;
  text-align: center;
  vertical-align: middle;
  color: #afafaf;
  table-layout: fixed;
  line-height: 4rem;
  position: -webkit-sticky;
  position: sticky;
  top: 21.5rem;
  z-index: 100;
}

.scrollableTbody {
  height: 100% !important;
}

.exchangeTableBody {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.financesContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.exchangesContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.totalsVelocityContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.totalsInvoiceContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
}

.financesContent table,
.financesContent td,
.totalsInvoiceContent table,
.totalsInvoiceContent td,
.totalsVelocityContent td,
.totalsVelocityContent table {
  border: 2px solid #2f3238;
  border-collapse: collapse;
  table-layout: fixed !important;
}

.financesContent td,
.totalsInvoiceContent td,
.totalsVelocityContent td {
  height: 4rem;
}

.financesContent td:first-child {
  width: 4rem !important;
}

.exchangesContent td,
.exchangesContent td {
  height: calc(4.1rem);
  width: 10rem;
}

.financesContent .tableMarkedRow {
  background-color: #15826e;
  color: white;
}

.financesInvCells {
  text-align: center;
  border: none;
  background-color: #2f3238;
  color: #afafaf;
  width: 6rem !important;
  cursor: pointer;
  font-size: 1.2rem;
  max-width: 3rem;
}

.financesInvCells td {
  height: 4rem;
  width: 6rem !important;
}

.financesIcon {
  height: 3.3rem;
  width: 3.3rem;
  margin: auto;
}

img.financesIcon {
  border-radius: 5px;
}

.financesIconBtnCont {
  display: flex;
  background-color: #2f3238;
  height: 4rem;
  width: 4rem;
  margin: auto;
}

.financesGameBtnCont {
  display: flex;
  background-color: #2f3238;
  height: 4rem;
  width: 4rem;
}

.financesGameButton {
  display: flex;
  height: 4rem;
  width: 4rem;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
}

.lastUpdateInfo {
  display: inline-block;
  color: #00d5a9;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3rem;
  cursor: pointer;
  float: right;
  padding-right: 0.3rem;
  padding-top: 2.3rem;
  vertical-align: bottom;
  z-index: 11;
}

.lastUpdateTooltip {
  background-color: #404040;
  position: absolute;
  width: 23rem;
  height: 5rem;
  top: 20rem;
  left: 30rem;
  z-index: 101;
  font-size: 1.2rem;
  border: 2px solid #353535;
}

.lastUpdateTooltipText {
  padding: 0.5rem;
}

.lastUpdateTooltipDate {
  margin-left: 1rem;
  color: #00d5a9;
  text-align: center;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 14rem;
}

@media only screen and (max-width: 1px) {
  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.4rem;
    color: #fff;
  }
}

@media only screen and (max-width: 1px) {
  .exchangesContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 8rem;
    left: 14rem;
    color: #fff;
  }

  .totalsVelocityContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 15rem;
    left: calc(2rem + 22vw);
    color: #fff;
  }

  .totalsInvoiceContent {
    position: fixed;
    height: auto;
    width: calc(15% + 15rem);
    top: 22rem;
    left: 14rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;
    width: calc(85% - 40.2rem);
    height: 21rem;
    top: 8.2rem;
    left: calc(15% + 30rem);
    color: #fff;
  }

  .financesContent {
    position: absolute;
    height: auto;
    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 23rem;
    left: 4rem;
    color: #fff;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;

    table-layout: fixed;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 8rem;
    width: 100vw;
    height: 31.5rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 29.5rem;
    z-index: 100;
  }

  .totalsInvoiceContent td,
  .totalsVelocityContent td {
    height: 2rem;
  }

  .exchangesContent td {
    height: 2.4rem;
  }
}

@media only screen and (max-width: 1px) {
  .exchangesContent {
    position: fixed;
    height: auto;

    width: calc(30% - 7rem);
    top: 8rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .totalsVelocityContent {
    position: fixed;
    height: auto;

    width: calc(35% - 8rem);
    top: 8rem;
    left: calc(30% + 7rem);
    color: #fff;
    z-index: 100;
  }

  .totalsInvoiceContent {
    position: fixed;
    height: auto;
    width: calc(35% - 8rem);
    top: 8rem;
    left: calc(65% - 2rem);
    color: #fff;
    z-index: 100;
  }

  .financesContent {
    position: absolute;

    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 31.5rem;
    left: 4rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;

    width: calc(100% - 24.4rem);
    top: 16rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 1.2rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 0rem;
    width: 100%;
    height: 39.5rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;

    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 37.5rem;
    z-index: 100;
  }
}

@media only screen and (max-width: 1px) {
  .chartTitle {
    color: #fff;
    padding: 0.1rem;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 600;
    margin: 0.5rem;
    border-bottom: 1px solid #606064;
    margin-top: 0;
  }

  .totalsInvoiceContent td,
  .totalsVelocityContent td {
    height: 1rem;
  }

  .exchangesContent td {
    height: 1.4rem;
  }

  .tableContent {
    background-color: #282b30;
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    line-height: 1rem;
    table-layout: auto;
  }

  .tableHeader {
    background-color: #24272b;
    text-align: center;
    border: 2px solid #2f3238;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #fff;
  }

  .revenueChartContent {
    position: fixed;
    height: 17rem;
    width: calc(100% - 24.4rem);
    top: 13rem;
    left: 14.2rem;
    color: #fff;
    z-index: 100;
  }

  .financesContent {
    position: absolute;
    /: auto;
    margin: 0 0 4rem;
    width: calc(100% - 8rem);
    top: 24rem;
    left: 4rem;
    color: #fff;
  }

  .scrollBackground {
    top: 6rem;
    left: 0rem;
    width: 100%;
    height: 32.8rem;
    background-color: #2f3238;
    z-index: 99;
  }

  .tableContentSticky {
    background-color: #282b30;
    /: 100 %;
    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    color: #afafaf;
    table-layout: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 30.5rem;
    z-index: 100;
  }
}

.dropdownBox {
  top: 0.5rem;
  right: 0.1rem;
  z-index: 2;
  width: 10rem;
  display: inline-block;
  float: right;
  margin-bottom: 20px;
}

.chartTitle {
  color: #fff;
  padding-bottom: 0.6rem;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 600;
  margin: 1rem;
  border-bottom: 1px solid #606064;
  margin-top: 0;
}

.baseChart {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 6rem;
  background: #282b30;

  height: 100%;
  width: 100%;
}

.baseChart ::-moz-selection {
  background: transparent;
}

.baseChart ::selection {
  background: transparent;
}

.baseChart ::-moz-selection {
  background: transparent;
}

.dropdownBox select {
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  display: inline-block;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3a3a3a;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.dropdownBox select option {
}

.dropdown-toggle {
  background-color: transparent !important;
}

/*
.dropdown {
  margin-right: 1rem;
  color: #fff;
  font-weight: normal; }

.field {
  padding: 0;
  color: #fff; }

.values {
  padding-left: 0;
  color: #ffffff;
  background-color: #2f3238 !important; }

.templateValue {
  min-height: initial;
  padding: 0.8rem;
  border-radius: 0.2rem;
  font-weight: normal;
  background: none;
  color: #fff; }
.templateValue::after {
  border-top-color: #fff; }

.inputLabel {
  color: #fff; }

.inputValue {
  color: #fff; }

.inputTemplateValue {
  color: #fff; }

.inputElement {
  border-bottom-color: #ffffff;
  margin: 0 .5rem;
  width: calc(100% - 1rem);
  text-align: center;
  color: #ffffff; }
.inputElement::placeholder {
  color: #ffffff; }
*/

.popup {
  position: fixed;
  width: 50rem;
  height: auto;
  top: 50%;
  left: 50%;

  border-radius: 5px;
  padding-top: 2rem;
  padding-right: 6rem;
  padding-left: 6rem;
  padding-bottom: 2rem;
  background-color: #24272b;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 101;
}

.popupForm {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #818184;
}

.popup .textfield.formText {
  width: 65%;
}

label.labelForm {
  display: inline-block;
  padding-right: 10px;
  width: 100px;
  text-align: right;
}

label.labelGroups {
  display: inline-block;
  clear: both;
  padding-right: 10px;
  width: 100px;
  text-align: right;
  float: left;
}

input {
  display: inline-block;
}

.submitBtn {
  width: 10rem;
  margin-right: 5rem;
}

.submitMoreBtn {
  width: 20rem;
}

.submitEdit {
  width: 10rem;
}

.deleteBtn,
.cancelBtn {
  width: 10rem;
}
.deleteBtn {
  margin-right: 5rem;
}

.checkboxItem.popupCheckbox {
  font-size: 12px;
  letter-spacing: 0.075rem;
}

.popupUser .textfield {
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 260px;
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.pupupUser input::-webkit-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.pupupUser input::-moz-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.pupupUser input::-ms-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.pupupUser input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.popupUser input:focus {
  background-color: rgb(31, 34, 36);
}

.labelContainer {
  margin-bottom: 20px;
}

.popup button {
  position: relative;
  width: 16rem;
  bottom: 0rem;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 2px 3px #000000;
  margin: 2px 1rem 3px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.popup button:hover {
  margin: 3px 1rem 2px;
  box-shadow: 0 0 1px #606064;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.confrmationContainer {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 520px;
}

.confrmationContainer button {
  all: unset;
  flex-grow: 1;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 4rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 2px 3px #000000;
  margin: 2px 1rem 3px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.confrmationContainer button:hover {
  margin: 3px 1rem 2px;
  box-shadow: 0 0 1px #606064;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.fetchDataerrorMessage {
  position: fixed;
  width: 50rem;
  height: auto;
  top: 50%;
  left: 50%;
  font-size: 1.6rem;
  line-height: 2.5rem;
  border-radius: 5px;
  padding-top: 0rem;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
  background-color: #24272b;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 101;
}
.fetchDataerrorMessage h3 {
  text-align: center;
  font-weight: 600;
  color: red;
  font-size: 1.8rem;
}

.clicker {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
}

input[type='text']:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0 0 5px rgb(21, 130, 110);
  border: 1px solid rgb(21, 130, 110);
}

.closeButton {
  line-height: 10px;
  width: 10px;
  height: 10px;
  font-size: 12pt;
  font-family: tahoma;
  font-weight: 800;
  text-align: center;
  margin: 2rem;
  padding: 0rem !important;
  position: absolute;
  color: #04d5ac;
  top: 0;
  right: 0;
  cursor: pointer;
}

td.tEmail {
  width: 30rem;
  line-height: 1.5;
}

td.tName {
  line-height: 1.5;
}

td.tGroups {
  width: 21rem;
  line-height: 1.5;
}

td.tLastActive {
  width: 24rem;
}

td.financesInvCells {
  border: none !important;
  padding: 0;
}

input.adminFilter.textfield {
  margin-bottom: 0px;
}


.textfield {
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  height: 30px;
  box-sizing: border-box;
  padding-left: 10px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
  width: 100%;
  padding-right: 10px;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

input::-moz-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

input::-ms-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

input:focus {
  background-color: rgb(31, 34, 36);
}



.trendsContent {
  grid-column: 2/4;
  grid-row: 2;
  top: 6rem;
  color: #fff;
}

.trendsContent table {
  border: 0px solid #2f3238;
  border-collapse: collapse;
  table-layout: auto !important;
  height: 4rem;
}

.trendsContent td {
  border: 2px solid #36393f;
  border-collapse: collapse;
  table-layout: auto !important;
  height: 86px;
  padding: 1rem;
  cursor: pointer;
}

.trendsContent td:first-child {
  width: 3rem !important;
}

.trendsTableContent {
  background-color: #282b30;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  font-size: 1.3rem;
  text-align: center;
  vertical-align: middle;
  color: #99999c;
  table-layout: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.trendsTableHeader {
  background-color: #24272b;
  text-align: center;
  border: 0px solid #2f3238;
  font-size: 12px;

  color: #e1e1e5;
  font-weight: bolder;
  letter-spacing: 0.05rem;
}

.trendsTableHeader td {
  cursor: default !important;
  height: 91px;
}

.trendsTableHeader p {
  font-size: 10px;
  padding-top: 0px;
  color: #99999c;
  font-weight: 400;
}

.trendsTableHeader tr td {
  padding-top: 18px;
  min-width: 102px;
}


.kpiGameDetailsContainer {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #2f3238;
  box-sizing: border-box;
  overflow: auto;
}

.kpiViewTitle {
  width: 80%;
  color: #fff;
  padding: 0rem 0rem 1.2rem;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
  border-bottom: 1px solid #606064;
  margin-top: 4rem;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  z-index: 101;
}

.kpiGameDetailsGridContainer {
  display: grid;
  grid-auto-rows: 9rem;
  grid-template-columns: 12rem 14rem 14rem 14rem 14rem 14rem 14rem 15rem;
  grid-template-rows: 11rem 5rem 7rem;
  grid-gap: 0.2rem;
  grid-row-gap: 2.5rem;
}

.kpiDtInfo {
  grid-column: 1/2;
  grid-row: 1;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.05rem;
  margin-top: 4rem;
  color: #99999c;
}

.kpiDtInfo span {
  display: block;
  height: 3rem;
  text-align: right;
  padding-right: 15px;
}

.totalContainer {
  display: grid;
  grid-template-column: 1rem 14rem 14rem 1rem;
}

.kpiDataRange {
}

.tldt1 {
  grid-column: 2/3;
  grid-row: 1;
}

.tldt2 {
  grid-column: 3/4;
  grid-row: 1;
}

.kpiGameDetailsGridContainer .dt1 {
  grid-column: 2 / 3;
  grid-row: 1;
}

.kpiGameDetailsGridContainer .dt2 {
  grid-column: 3;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt3 {
  grid-column: 4 / 5;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt4 {
  grid-column: 5 / 6;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt5 {
  grid-column: 6 /7;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt6 {
  grid-column: 7 / 8;
  grid-row: 1;
}
.kpiGameDetailsGridContainer .dt7 {
  grid-column: 2/3;
  grid-row: 2;
}

.kpiGameDetailsGridContainer .dt8 {
  grid-column: 3 / 4;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt9 {
  grid-column: 4 / 5;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt10 {
  grid-column: 5 / 6;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt11 {
  grid-column: 6 / 7;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt12 {
  grid-column: 8 / 9;
  grid-row: 2;
}
.kpiGameDetailsGridContainer .dt13 {
  grid-column: 2/3;
  grid-row: 3;
}

.kpiGameDetailsGridContainer .dt14 {
  grid-row: 3;
  grid-column: 3/4;
}
.kpiGameDetailsGridContainer .dt15 {
  grid-row: 3;
  grid-column: 4/5;
}
.kpiGameDetailsGridContainer .dt16 {
  grid-row: 3;
  grid-column: 5/6;
}
.kpiGameDetailsGridContainer .dt17 {
  grid-row: 3;
  grid-column: 6 /7;
}
.kpiGameDetailsGridContainer .dt18 {
  grid-row: 3;
  grid-column: 7/8;
}
.kpiGameDetailsGridContainer .dt19 {
  grid-row: 2;
  grid-column: 7/8;
}
.DateRangeIndicator {
  height: 4rem;
  color: #c7c7c7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
.DateRangeIndicatorClicker {
  padding: 0.5rem;
  background: rgba(31, 31, 31, 0.95);
  border: 2px solid #606064;
}

.kpiPlatformPicker {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: left;
  padding: 0.2rem;
}

.kpiPlatformPickerButton {
  cursor: pointer;
  width: 140px;
  height: 34px;
  border-color: rgb(28, 28, 28);
  border-radius: 3px;
  background-image: -ms-linear-gradient(
    90deg,
    rgb(36, 39, 43) 0%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%,
    rgb(40, 43, 48) 100%
  );
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.44), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.25);
  /*box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);*/
  font-style: normal;
  padding-top: 1px;
  margin-right: 3rem;
  border-style: solid;
  border-width: 1px;
  font-size: 1.3rem;
  line-height: 3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

.kpiPlatformPickerButtonSelected {
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  box-sizing: border-box;
}

.kpiPlatformPicker img {
  z-index: 100;
  position: relative;
  top: 4px;
  left: 3px;
  height: 2rem;
  padding-right: 30px;
  padding-left: 15px;
  text-align: left;
}

.kpiGameDetailsTotalLoan {
  width: 12rem;
  height: 2rem;
}

.kpiGameDetailsPeriodPicker {
  margin-left: 1rem;
}

.kpiGameDetailsRagStatus {
  width: 40rem;
}

.kpiGameDetailsDataTile {
  height: auto;
  width: 13rem;
  border: 1px solid #36393f;
  margin-right: 0rem;
  margin-bottom: 0rem;
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: bolder;
  display: inline-grid;
}

.kpiGameDetailsLabel {
  height: 3rem;
  text-align: center;
  display: block;
  color: #ffffff;
  background: #24272b;
  letter-spacing: 0.05rem;
  line-height: 3rem;
  font-weight: 500;
}

.kpiGameDetailsValues {
  height: 3rem;
  text-align: center;
  display: block;
  font-weight: normal;
  background-color: #282b30;
  color: #99999c;
  border-top: 1px solid #36393f;
  line-height: 3rem;
  box-sizing: border-box;
}

.kpiGameDetailsIgnitionStatusContainer {
  position: absolute;
  width: 40rem;
  top: 22rem;
  left: 6rem;
}

.componentP {
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 2rem;
  height: 22rem;
  width: 40rem;
}

.box {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 0.5rem;
  background-color: #282b30;
  border: 1px solid #ffa119;
  color: #ffffff;
  text-align: center;
}
.box p {
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.box span {
  width: 10rem;
  float: right;
  line-height: 3rem;
}

.box span:first-child {
  float: left;
  text-align: left;
  padding-left: 1rem;
}

.box p:nth-child(even) {
  background-color: #24272b;
}

.boxRed {
  border-color: #f95846;
}

.boxGreen {
  border-color: #5bb960;
}

.header {
  padding: 0.3rem 1rem;
  margin: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: -webkit-gradient(linear, left top, right top, from(#ffa119), to(#7e4316));
  background: linear-gradient(to right, #ffa119 0%, #7e4316 100%);
  font-size: 1.8rem;
  line-height: 4rem;
  font-weight: bold;
}

.headerRed {
  background: -webkit-gradient(linear, left top, right top, from(#f95846), to(#7f3f2f));
  background: linear-gradient(to right, #f95846 0%, #7f3f2f 100%);
}

.headerGreen {
  background: -webkit-gradient(linear, left top, right top, from(#5bb960), to(#214321));
  background: linear-gradient(to right, #5bb960 0%, #214321 100%);
}

.daysForRagStatus {
  margin-top: 2rem;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 4rem;
}

.kpiDataRange .RangePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 600px;
  top: 955px;
  left: 60px;
  z-index: 1009;
}

.kpiDataRange .RangePickerInfo {
  width: 30%;
  margin-top: 10px;
  background-color: #2f3238;
  z-index: 1080;
  text-align: left;
}

.cpiTile {
  grid-column: 8/9;
  grid-row: 3;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.loginForm {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 260px;
  max-width: 90%;
}

.loginForm button {
  width: 260px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(30, 32, 34);
  border-radius: 3px;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(255, 255, 255);
  font-weight: medium;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  text-align: center;
  background-image: -ms-linear-gradient(90deg, rgb(12, 173, 141) 0%, rgb(0, 213, 169) 100%);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.36), inset 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.loginForm button:hover {
  background-image: -ms-linear-gradient(90deg, rgb(23, 132, 113) 0%, rgb(0, 213, 169) 100%);
}

.loginForm button:focus {
  outline: none;
}

.loginForms button {
  font-size: 1.3rem;
  width: 100%;
  font-weight: 600;
  line-height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.71);
  border-radius: 6px;
  box-shadow: 0 1px 2px #000000;
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 3px;
  -webkit-transition: all 0.01s linear;
  transition: all 0.01s linear;
  background-color: #15826e;
  color: white;
  cursor: pointer;
}

.loginForms button:hover {
  margin-top: 1rem;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-bottom: 3px;
  box-shadow: 0 0 0px #000000;
  -webkit-transition: all 0.01s linear;
  transition: all 0.01s linear;
}

.loginForm input[type='email'] {
  width: 100%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  line-height: 3rem;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.loginForm input[type='password'] {
  width: 100%;
  margin-top: 1rem;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1rem;
  line-height: 3rem;
  border-radius: 3px;
  background-color: rgb(30, 32, 34);
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.2), inset 0px 1px 1px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: none;
  outline: none;
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: rgb(225, 225, 229);
  letter-spacing: 0.05em;
}

.loginForm input::-webkit-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.loginForm input::-moz-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.loginForm input::-ms-input-placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.loginForm input::placeholder {
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(52, 57, 64);
  letter-spacing: 0.05em;
}

.loginForm input:focus {
  background-color: rgb(31, 34, 36);
}

.loginForm button:focus {
  outline: none;
}

/* SWITCH */
.toogleContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.toogleLabel {
  color: #5b616e;
  font-size: 1.3rem;
  margin-left: 0.4rem;
}

.toogleContainer input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
}

.toogleContainer label {
  margin: 0;
  cursor: pointer;
  text-indent: -9999px;
  width: 3.5rem;
  height: 1.3rem;
  background: #1e2022;
  display: block;
  border-radius: 1.8rem;
  position: relative;
}

.toogleContainer label:after {
  content: '';
  position: absolute;
  top: -0.25rem;
  left: 0rem;
  width: 1.8rem;
  height: 1.8rem;
  background: #40444c;
  border-radius: 1.8rem;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.toogleContainer input:checked + label {
  background: #009476;
  -webkit-transition: all 0.55s linear;
  transition: all 0.55s linear;
}

.toogleContainer input:checked + label:after {
  left: 100%;
  background: #0e6355;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.toogleContainer label:active:after {
  width: 3.5rem;
}

.toogleLabel {
  font-size: 1.3rem;
  font-family: 'Roboto', sans-serif;
  color: #5b616e;
}

.LoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* scrollbar for webkit engine .scrolledItemsContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #242629;
}

.scrolledItemsContainer::-webkit-scrollbar {
  width: 6px;
  background-color: #242629;
  border-radius: 10px;
}

.scrolledItemsContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #00d5a9;
}
*/









html,
body {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}

#root {
  min-height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: 'Roboto', sans-serif;
  /*font-family: 'Titillium Web', sans-serif;*/
}

body {
  position: relative;
  font-size: 1.6rem;
  /*overflow-x: hidden;*/
  color: #fafafa;
  background-color: #2f3238;
  -webkit-overflow-scrolling: touch;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

