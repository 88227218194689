.fetchDataerrorMessage {
  position: fixed;
  width: 50rem;
  height: auto;
  top: 50%;
  left: 50%;
  font-size: 1.6rem;
  line-height: 2.5rem;
  border-radius: 5px;
  padding-top: 0rem;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
  background-color: #24272b;
  transform: translate(-50%, -50%);
  z-index: 101;
}
.fetchDataerrorMessage h3 {
  text-align: center;
  font-weight: 600;
  color: red;
  font-size: 1.8rem;
}

.clicker {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
  background-color: #282b30;
  opacity: 0.7;
  z-index: 100;
}

input[type='text']:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0 0 5px rgb(21, 130, 110);
  border: 1px solid rgb(21, 130, 110);
}

.closeButton {
  line-height: 10px;
  width: 10px;
  height: 10px;
  font-size: 12pt;
  font-family: tahoma;
  font-weight: 800;
  text-align: center;
  margin: 2rem;
  padding: 0rem !important;
  position: absolute;
  color: #04d5ac;
  top: 0;
  right: 0;
  cursor: pointer;
}

td.tEmail {
  width: 30rem;
  line-height: 1.5;
}

td.tName {
  line-height: 1.5;
}

td.tGroups {
  width: 21rem;
  line-height: 1.5;
}

td.tLastActive {
  width: 24rem;
}

td.financesInvCells {
  border: none !important;
  padding: 0;
}

input.adminFilter.textfield {
  margin-bottom: 0px;
}
